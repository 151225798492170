import { Injectable } from '@angular/core';
import { BaseClient } from '@app-clients/base.client';
import { HttpClient } from '@angular/common/http';
import { AppEnvironment } from '@app-services/environment/app-environment.service';
import { User } from '@app-models/user/user';
import { CreatePerson } from '@app-models/people/create-person';
import { ApiResponse } from '@app-responses/api-response';

/**
 * Implementation of the default client to communicate with
 * the current user API.
 */
@Injectable()
export class CurrentUserClient extends BaseClient {
  constructor(httpClient: HttpClient, appEnvironment: AppEnvironment) {
    super('users/current', httpClient, appEnvironment);
  }

  async getUser(): Promise<User> {
    const response = await this.get<ApiResponse<User>>();
    return response.data;
  }

  update(userInformation: CreatePerson): Promise<User> {
    return this.put(userInformation);
  }
  updatePassword(currentPassword: string, newPassword: string): Promise<void> {
    return this.patch(
      {
        currentPassword,
        newPassword,
      },
      ['updatepassword'],
    );
  }
}
