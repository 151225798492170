import { Injectable } from '@angular/core';
import { AssessmentStore } from '@app-state/assessment/assessment.store';
import { BaseStateService } from '@app-state/shared/base-state.service';
import { AssessmentState } from '@app-state/assessment/assessment.state';
import { AssessmentClient } from '@app-clients/assessment/assessment-client.service';
import { AssessmentRevisionStore } from '@app-state/assessment-revision';
import { AssessmentVariantStore } from '@app-state/assessment-variant';
import { Assessment } from '@app-models/assessment';
import { normalizeAssessments } from '@app-state/assessment/assessment.normalizer';
import { AccountQuery } from '@app-state/account';
import { AssessmentCompleteBetween } from '@app-models/assessment/extract-data/assessment-complete-between';

/**
 * Class for manipulating assessment store
 */
@Injectable()
export class AssessmentService extends BaseStateService<AssessmentState> {
  constructor(
    private readonly _assessmentStore: AssessmentStore,
    private readonly _assessmentClient: AssessmentClient,
    private readonly _accountQuery: AccountQuery,
    private readonly _assessmentRevisionStore: AssessmentRevisionStore,
    private readonly _assessmentVariantStore: AssessmentVariantStore,
  ) {
    super(_assessmentStore);
  }

  /**
   * Loads all assessment families
   */
  async loadAssessments(): Promise<void> {
    this.startLoading();

    const assessments = await this._assessmentClient.getAssessments(
      this._accountQuery.getActive().accountId,
    );

    this.resetAllStores();
    this.extractDataFromResponse(assessments);

    this.stopLoading();
  }

  public async getCompletedRevisionCount(
    revisionInformation: AssessmentCompleteBetween,
  ): Promise<number> {
    return await this._assessmentClient.getCompletedRevisionCount(
      revisionInformation,
    );
  }

  private extractDataFromResponse(
    assessments: Assessment | Assessment[],
  ): void {
    const normalizedData = normalizeAssessments(assessments);

    this._assessmentRevisionStore.set(
      normalizedData.entities.assessmentRevisions,
    );
    this._assessmentStore.set(normalizedData.entities.assessments);
    this._assessmentVariantStore.set(
      normalizedData.entities.assessmentVariants,
    );
  }

  private startLoading(): void {
    this._assessmentRevisionStore.setLoading(true);
    this._assessmentStore.setLoading(true);
    this._assessmentVariantStore.setLoading(true);
  }

  private stopLoading(): void {
    this._assessmentRevisionStore.setLoading(false);
    this._assessmentStore.setLoading(false);
    this._assessmentVariantStore.setLoading(false);
  }

  private resetAllStores(): void {
    this._assessmentStore.reset();
    this._assessmentRevisionStore.reset();
    this._assessmentVariantStore.reset();
  }
}
