<svg
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
  viewBox="0 0 250 50"
  shape-rendering="geometricPrecision"
  text-rendering="geometricPrecision"
  [attr.width]="width"
  [attr.height]="height"
  style="background-color: #6763b4; display: block">
  <text
    dx="0"
    dy="0"
    font-family='"Roboto"'
    font-size="20"
    font-weight="400"
    [attr.transform]="transform"
    fill="#fff"
    stroke-width="0">
    <tspan y="0" font-weight="400" stroke-width="0">
      {{ title }}
    </tspan>
  </text>
</svg>
