import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { TaskQueue } from '@app-utils/task-queue';
import { defaultLoaderOptions, LoaderOptions } from '@app-components/ui/loader';

/**
 * Service for freezing the screen UI. When frozen any screen interactions are disabled.
 */
@Injectable({
  providedIn: 'root',
})
export class FreezeUi {
  /*
    Run any change in async loop to get picked up on the next CD cycle.
    If we don't do this then we are probably going to get CD error
    because it is more than likely that child will unfreeze parent.
   */

  private _isFrozen = false;
  private _freezeText: string;
  private _options: LoaderOptions;

  readonly isFrozen$ = new BehaviorSubject(false);

  /**
   * Indicates whether UI is frozen
   * @returns {boolean} - true is UI is frozen otherwise false
   */
  get isFrozen(): boolean {
    return this._isFrozen;
  }

  /**
   * Gets the text that should be displayed while UI is frozen
   * @returns {string} - text to show in loader
   */
  get freezeText(): string {
    return this._freezeText;
  }

  /**
   * Options for the loader
   */
  get options(): LoaderOptions {
    return this._options;
  }
  /**
   * Indicates whether custom text has been set on this loader.
   * @returns {boolean} - true if text has been set with loader otherwise false
   */
  get hasText(): boolean {
    return !!this._freezeText;
  }

  /**
   * Freezes the current UI.
   * @param text - Optional. Text to show during freezing. Defaults to null.
   * @param options - Optional. Object that configures the loader.
   */
  freezeUi(text: string = null, options?: Partial<LoaderOptions>): void {
    TaskQueue.scheduleOnMacroQueue(() => {
      this._isFrozen = true;
      this._freezeText = text;
      this._options = {
        ...defaultLoaderOptions(),
        ...options,
      };
    });
  }

  /**
   * Unfreezes the current UI.
   */
  unfreezeUi(): void {
    TaskQueue.scheduleOnMacroQueue(() => (this._isFrozen = false));
  }
}
