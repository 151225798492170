import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import * as environmentBase from '@environments/environment';
import { ApplicationEnvironment } from '@environments/application-environment';

if (
  environmentBase.environment.applicationEnvironment ===
  ApplicationEnvironment.Production
) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  // eslint-disable-next-line no-console
  .catch(err => console.error(err));
