import { NgModule } from '@angular/core';
import { PrintContainerComponent } from '@app-components/ui/print-container/print-container.component';
import { PrintService } from '@app-services/ui/print.service';
import { CommonModule } from '@angular/common';

/**
 * Module that holds components and services for print function. This module should be only imported at the top
 * level component (most likely AppComponent) to be available application wide. In this case component declarations
 * and service providers can be declared in this module together.
 */
@NgModule({
  declarations: [PrintContainerComponent],
  exports: [PrintContainerComponent],
  imports: [CommonModule],
  providers: [PrintService],
})
export class PrintModule {}
