import { EntityStore, StoreConfig } from '@datorama/akita';
import { AssessmentRevisionState } from '@app-state/assessment-revision/assessment-revision.state';
import { Stores } from '@app-state/shared/stores-configuration';

@StoreConfig(Stores.assessmentRevisions)
export class AssessmentRevisionStore extends EntityStore<AssessmentRevisionState> {
  constructor() {
    super();
  }
}
