import { Component } from '@angular/core';

/**
 * Renders a page section title
 */
@Component({
  selector: 'cp-cus-section-title',
  template: `
    <span
      class="block my-4 bg-gray-200 dark:bg-gray-700 text-2xl px-3 py-2 rounded">
      <ng-content></ng-content>
    </span>
  `,
})
export class SectionTitleComponent {}
