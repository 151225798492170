import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'cp-cus-snackbar',
  templateUrl: './snackbar.component.html',
})
export class SnackbarComponent {
  @Input() title: string;

  @Input() icon: string;

  @Input() closeable: boolean;

  @Input({ required: true }) color: string;

  @Output() readonly snackbarClosed: EventEmitter<void> =
    new EventEmitter<void>();
}
