import { User } from '@app-models/user/user';
import { Account } from '@app-models/account/account';

/**
 * State for logged in session data
 */
export interface SessionState {
  user: User;
  account: Account;
}

/**
 * Gets the initial session state
 */
export function createInitialSessionState(): SessionState {
  return {
    user: null,
    account: null,
  };
}
