// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import packageInfo from '../../package.json';
import { KeycloakConfig } from 'keycloak-js';
import {
  ApplicationEnvironment,
  Environment,
} from '@environments/application-environment';

export const keycloakConfig: KeycloakConfig = {
  realm: 'HighMatch',
  clientId: 'admin-ui',
  url: '',
};

export const environment: Environment = {
  version: packageInfo.version,
  applicationEnvironment: ApplicationEnvironment.Development,
  baseApiUrl: '',
  participantBaseUrl: '',
  publisherUrl: '',
  reportUrl: '',
};
