import { Injectable } from '@angular/core';
import { UserQuery } from '@app-state/user/user.query';
import { UserStore } from '@app-state/user/user.store';
import { UsersClient } from '@app-clients/people/users.client';
import { User } from '@app-models/user/user';
import { UserFilter } from '@app-models/user/user-filter';
import { CreateUser } from '@app-models/user/create-user';
import { LoggingService } from '@app-services/environment/logging.service';
import { AddRemoveAccount } from '@app-models/people/add-remove-account';
import { AuthService } from '@app-services/security/auth.service';

/**
 * Service for manipulating user store.
 */
@Injectable()
export class UserService {
  private readonly PAGE_SIZE = 25;

  private _loadInProgressVar = false;

  constructor(
    private readonly _userStore: UserStore,
    private readonly _userClient: UsersClient,
    private readonly _userQuery: UserQuery,
    private readonly _logger: LoggingService,
    private readonly _authService: AuthService,
  ) {}

  private get _loadInProgress(): boolean {
    return this._loadInProgressVar;
  }
  private set _loadInProgress(value: boolean) {
    this._loadInProgressVar = value;
    this._userStore.setLoading(value);
  }

  /**
   * Toggles user activation status
   * @param personId Person ID of the user
   */
  async toggleActivation(personId: string): Promise<boolean> {
    try {
      await this._userClient.toggleActivation(personId);
    } catch (error) {
      this._logger.logError(error);
      return false;
    }

    this._userStore.update(personId, user => {
      return {
        ...user,
        isActive: !user.isActive,
        inactivatedOn: new Date(),
      };
    });

    return true;
  }
  /**
   * Creates a new user in the system
   * @param user User representation
   */
  async create(user: CreateUser): Promise<User> {
    let newUser: User;
    try {
      newUser = await this._userClient.create(user);
    } catch (error) {
      this._logger.logError(error);
      return null;
    }

    this._userStore.add(newUser);
    return newUser;
  }
  /**
   * Updates an existing user in the system
   * @param personId Person ID of the user to update
   * @param user New user representation
   */
  async update(personId: string, user: CreateUser): Promise<User> {
    try {
      const updatedUser = await this._userClient.update(personId, user);
      this._userStore.update(personId, updatedUser);
      return updatedUser;
    } catch (error) {
      this._logger.logError(error);
      return undefined;
    }
  }

  async addToAccount(addData: AddRemoveAccount): Promise<boolean> {
    try {
      await this._userClient.addToAccount(addData);

      this.updateUserAccountStore(
        addData.personId,
        addData.accountId,
        addData.isOwner,
      );
      return true;
    } catch (error) {
      this._logger.logError(error);
      return false;
    }
  }

  setActive(personId: string): void {
    this._userStore.setActive(personId);
  }

  async removeFromAccount(removeData: AddRemoveAccount): Promise<boolean> {
    try {
      await this._userClient.removeFromAccount(removeData);

      this.updateUserAccountStore(
        removeData.personId,
        removeData.accountId,
        removeData.isOwner,
        true,
      );

      return true;
    } catch (error) {
      this._logger.logError(error);
      return false;
    }
  }

  /**
   * Adds or removes a user role in the user store
   * @param personId ID of the user to update
   * @param accountId ID of the account for the role
   * @param shouldRemove Boolean indicating if the account should be removed from the person
   * @param isOwner Boolean indicating if the user is being added as an account owner
   */
  updateUserAccountStore(
    personId: string,
    accountId: string,
    isOwner: boolean,
    shouldRemove = false,
  ): void {
    const personAccounts = this._userQuery.getEntity(personId)?.accounts;
    const ownedAccounts = this._userQuery.getEntity(personId)?.ownedAccounts;

    if (!personAccounts) return;

    if (shouldRemove) {
      accountId === this._authService.currentAccount.accountId &&
        isOwner &&
        this._userStore.update(personId, { isAccountOwner: false });

      this._userStore.update(personId, {
        ownedAccounts: ownedAccounts.filter(account => account !== accountId),
        accounts: personAccounts.filter(account => account !== accountId),
      });
      return;
    }

    isOwner &&
      this._userStore.update(personId, {
        ownedAccounts: [...ownedAccounts, accountId],
      });
    this._userStore.update(personId, {
      accounts: [...personAccounts, accountId],
    });
  }

  /**
   * Loads the next page of users
   * @param forceReload If true reload list from beginning. Defaults to false.
   */
  async loadPage(forceReload = false): Promise<void> {
    if (this._loadInProgress) return;

    this._loadInProgress = true;

    if (forceReload) {
      this._userStore.remove();
    }

    const participants = await this._userClient.getUsers(
      this._userQuery.getFilter(),
      {
        pageSize: this.PAGE_SIZE,
        page: Math.floor(this._userQuery.getCount() / this.PAGE_SIZE) + 1,
      },
    );

    this.setFullyLoaded(participants.length < this.PAGE_SIZE);

    this._userStore.add(participants);

    this._loadInProgress = false;
  }
  /**
   * Searches the user collection on the server
   * @param filter Filter to search user
   * @param maxResults Maximum results to return. Defaults to 25.
   */
  search(filter: UserFilter, maxResults = 25): Promise<User[]> {
    return this._userClient.getUsers(filter, { pageSize: maxResults, page: 1 });
  }
  /**
   * Marks user as active entity.
   * @param user Participant to mark as active
   */
  selectUser(user: Partial<User>): void {
    if (!user) return;

    this._userStore.setActive(user.personId);
  }
  /**
   * Clears the currently active user
   */
  unselectUser(): void {
    this._userStore.setActive(null);
  }

  /**
   * Updates the current UI filter with new values
   * @param filter Filter values to update
   */
  updateFilter(filter: Partial<UserFilter>): void {
    const currentFilter = this._userQuery.getFilter();
    this._userStore.updateUi({
      filter: {
        ...currentFilter,
        ...filter,
      },
    });
    this.reloadUsers(false);
  }
  /**
   * Sets a new value for the filter rewriting the current value.
   * @param filter Filter to set.
   */
  setFilter(filter: Partial<UserFilter>): void {
    this._userStore.updateUi({
      filter,
    });
    this.reloadUsers(false);
  }
  /**
   * Clears the UI filter.
   */
  clearFilter(): void {
    this._userStore.updateUi({ filter: {} });
    this.reloadUsers(false);
  }

  /**
   * Reloads the user store with optional reset.
   * @param withReset Option to reset the store to initial value
   */
  async reloadUsers(withReset = true): Promise<void> {
    if (withReset) {
      this._userStore.reset();
    } else {
      this._userStore.remove();
    }

    await this.loadPage();
  }

  private setFullyLoaded(value: boolean): void {
    this._userStore.update({ fullyLoaded: value });
  }

  /**
   * Checks if user email already exists
   * @param email email value entered in form
   */
  checkIfEmailExists(email: string): Promise<boolean> {
    return this._userClient.checkIfEmailExists(email);
  }

  /**
   * Loads a user by ID
   * @param personId Person ID of the user
   */
  async loadById(personId: string): Promise<User> {
    let user: User = null;
    try {
      user = await this._userClient.getById(personId);
      this._userStore.upsert(user.personId, user);
    } catch (error) {
      // swallow
    }

    return user;
  }
}
