import { EntityStore, SetActiveOptions } from '@datorama/akita';

/**
 * Base state for services with active entities
 */
export abstract class BaseStateService<TState> {
  protected constructor(protected store: EntityStore<TState>) {}

  /**
   * Clears the current active entity
   */
  clearActive(): void {
    this.setActive(null);
  }

  /**
   * Sets the active entity for the store
   * @param idOrOptions ID or options of the new active entity
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setActive(idOrOptions: any | SetActiveOptions): void {
    this.store.setActive(idOrOptions);
  }
}
