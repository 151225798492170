<div class="dialog-header flex items-center">
  <mat-icon class="mr-2 text-warnLight-500"> error </mat-icon>
  <span class="m-0 text-warnLight-500" mat-dialog-title
    >Something Unexpected Happened</span
  >
</div>
<div mat-dialog-content>
  <p>
    We are sorry. Part of this application appears to be blocked. Please click
    Refresh and try again.
  </p>
  <p>
    If this problem continues, please ask your network administrator or IT
    department to allow traffic to {{ appEnvironment.baseApiUrl }}.
  </p>
</div>
<div mat-dialog-actions>
  <button mat-flat-button color="primary" class="ml-auto" (click)="refresh()">
    Refresh
  </button>
</div>
