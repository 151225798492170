import { Component, Input, Output, EventEmitter } from '@angular/core';
import { SpinnerSize } from '@app-components/ui/spinner/spinner.component';
import { ThemePalette } from '@angular/material/core';
import { TooltipPosition } from '@angular/material/tooltip';

/**
 * Component to display button with spinner
 */
@Component({
  selector: 'cp-cus-spinner-button',
  template: `
    <button
      mat-flat-button
      [color]="color"
      [disabled]="spin || disabled"
      [matTooltip]="spin ? 'Please Wait...' : tooltip"
      [matTooltipPosition]="tooltipPosition"
      [ngClass]="panelClass"
      (click)="btnClick.emit($event)">
      <ng-content></ng-content>
      <cp-cus-spinner
        class="absolute inset-0 mx-auto my-0.5"
        *ngIf="spin"
        [size]="SpinnerSize.Small"></cp-cus-spinner>
    </button>
  `,
})
export class SpinnerButtonComponent {
  SpinnerSize = SpinnerSize;
  @Output() btnClick = new EventEmitter();
  /**
   * Disable button and show spinner
   */
  @Input()
  spin = false;
  /**
   * Disable the button
   */
  @Input()
  disabled = false;
  /**
   * Color of the button
   */
  @Input()
  color: ThemePalette = 'primary';

  /**
   * Optional class for button
   */
  @Input()
  panelClass: string = null;

  /**
   * Tooltip to show when not in spin mode
   */
  @Input()
  tooltip: string;

  /**
   * Position of tooltip
   */
  @Input()
  tooltipPosition: TooltipPosition = 'below';
}
