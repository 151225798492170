import { Component, Input } from '@angular/core';

@Component({
  selector: 'cp-cus-cancel-button',
  templateUrl: './cancel-button.component.html',
})
export class CancelButtonComponent {
  /**
   * Tooltip to show with the button.
   */
  @Input()
  tooltip = 'Cancel';
}
