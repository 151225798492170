import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';

/**
 * Service that controls the application theme
 */
@Injectable({
  providedIn: 'root',
})
export class ThemeService {
  private readonly DARK_MODE_KEY = 'hm-darkMode';
  private readonly BODY = document.querySelector('body');

  private readonly _renderer: Renderer2;
  private _darkMode = false;

  constructor(rendererFactory: RendererFactory2) {
    this._renderer = rendererFactory.createRenderer(null, null);
    this.loadDarkModeSetting();
  }

  /**
   * Indicates whether dark mode is enabled
   */
  get darkMode(): boolean {
    return this._darkMode;
  }

  /**
   * Enables or disables the dark mode
   * @param value
   */
  set darkMode(value: boolean) {
    this._darkMode = value;
    this.applyDarkModeClass(value);
    this.persistThemeSetting();
  }

  private persistThemeSetting(): void {
    localStorage.setItem(this.DARK_MODE_KEY, this._darkMode.toString());
  }
  private applyDarkModeClass(enabled: boolean): void {
    if (enabled) {
      this._renderer.addClass(this.BODY, 'dark');
    } else {
      this._renderer.removeClass(this.BODY, 'dark');
    }
  }
  private loadDarkModeSetting(): void {
    this._darkMode =
      (this.hasDarkModePreference() && this.isDarkModeUserPreferred()) ||
      (!this.hasDarkModePreference() && this.isDarkModeOsPreferred());
    this.applyDarkModeClass(this._darkMode);
  }
  //Indicates whether user set their preference in the application
  private hasDarkModePreference(): boolean {
    return localStorage.getItem(this.DARK_MODE_KEY) !== null;
  }
  //Indicates users' preference for the dark mode in application settings
  private isDarkModeUserPreferred(): boolean | null {
    const storedValue = localStorage.getItem(this.DARK_MODE_KEY);

    return storedValue && storedValue === 'true';
  }
  //Indicates whether user has their OS setting set to dark mode
  private isDarkModeOsPreferred(): boolean {
    return (
      window.matchMedia &&
      window.matchMedia('(prefers-color-scheme: dark)').matches
    );
  }
}
