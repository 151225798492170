import { PipeTransform, Pipe } from '@angular/core';

/**
 * This pipe sorts the provided array by the provided property.
 */
@Pipe({
  name: 'sort',
})
export class SortPipe implements PipeTransform {
  transform<TElement>(
    array: TElement[],
    sortBy?: keyof TElement,
    desc?: boolean,
  ): TElement[] {
    if (!array || array.length === 0) {
      return [];
    }

    if (
      sortBy &&
      array.some(e => e[sortBy] === undefined || e[sortBy] === null)
    ) {
      throw new Error(
        'At least one element in the sequence does not define the sortBy property.',
      );
    }

    return sortBy
      ? array
          .slice()
          .sort((x1: unknown, x2: unknown) =>
            this.compareFn(x1[sortBy], x2[sortBy], desc),
          )
      : array
          .slice()
          .sort((x1: unknown, x2: unknown) => this.compareFn(x1, x2, desc));
  }

  private compareFn(x1: unknown, x2: unknown, desc: boolean): number {
    if (typeof x1 === 'string' && typeof x2 === 'string') {
      x1 = x1.toLowerCase();
      x2 = x2.toLowerCase();
    }

    if (x1 < x2) return desc ? 1 : -1;
    if (x1 > x2) return desc ? -1 : 1;

    return 0;
  }
}
