import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '@app-guards/auth.guard';
import { UserNotFoundComponent } from './@pages/user-not-found/user-not-found.component';

const routes: Routes = [
  {
    path: 'app',
    loadChildren: () =>
      import('./@pages/main/main.module').then(m => m.MainModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'view',
    loadChildren: () =>
      import('./@pages/view/view.module').then(m => m.ViewModule),
  },
  {
    path: 'user-not-found',
    component: UserNotFoundComponent,
  },
  { path: '**', pathMatch: 'full', redirectTo: 'app' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule],
})
export class AppRoutingModule {}
