<div class="flex items-center bg-gray-300 dark:bg-gray-700 rounded-full p-1">
  <mat-icon class="dark:text-white">search</mat-icon>
  <input
    matInput
    [placeholder]="placeholder"
    [value]="initialValue"
    (cpCusObserveValue)="onChange($event)"
    #searchInput
    class="flex-1 outline-none ml-2 dark:text-white"
    (click)="$event.stopPropagation()" />
  <button
    type="button"
    mat-icon-button
    matTooltip="Clear"
    aria-label="Clear"
    class="dark:text-white"
    *ngIf="clearable && searchInput.value"
    (click)="clear(); $event.stopPropagation()">
    <mat-icon>close</mat-icon>
  </button>
</div>
