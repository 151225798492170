import { EntityStore, StoreConfig } from '@datorama/akita';
import { AssessmentVariantState } from '@app-state/assessment-variant/assessment-variant.state';
import { Stores } from '@app-state/shared/stores-configuration';

/**
 * Akita Store for assessment variants
 */
@StoreConfig(Stores.assessmentVariants)
export class AssessmentVariantStore extends EntityStore<AssessmentVariantState> {
  constructor() {
    super();
  }
}
