<div class="dialog-header flex items-center hm-success-dialog">
  <mat-icon class="hm-icon-width mr-2 -mt-4 text-successLight-500 text-3xl">
    check_circle
  </mat-icon>
  <span class="m-0 ml-2 text-successLight-500 text-3xl">Success</span>
</div>
<div mat-dialog-content>
  <p class="text-base" *ngIf="isSimpleMessage">{{ data.message }}</p>

  <ng-container *ngIf="!isSimpleMessage">
    <ng-container
      *ngTemplateOutlet="
        $any(data.message);
        context: data.templateContext ?? {}
      "></ng-container>
  </ng-container>
</div>
<div mat-dialog-actions>
  <button mat-flat-button mat-dialog-close color="primary" class="ml-auto">
    Close
  </button>
</div>
