import { Component, OnInit } from '@angular/core';
import { FreezeUi } from '@app-services/ui/freeze-ui.service';
import { Title } from '@angular/platform-browser';
import { AppEnvironment } from '@app-services/environment/app-environment.service';

@Component({
  selector: 'cp-cus-root',
  template: ` <div class="h-screen background">
    <router-outlet></router-outlet>
    <cp-cus-loader
      [loadingText]="freezeUi.freezeText"
      [isLoading]="freezeUi.isFrozen"
      [options]="freezeUi.options"></cp-cus-loader>
    <cp-cus-print-container></cp-cus-print-container>
  </div>`,
})
export class AppComponent implements OnInit {
  constructor(
    public freezeUi: FreezeUi,
    private readonly _appEnv: AppEnvironment,
    private readonly _title: Title,
  ) {}

  ngOnInit(): void {
    this.setDevPageTitle();
    this.freezeUi.freezeUi('Please wait');
  }

  private setDevPageTitle(): void {
    if (this._appEnv.isProduction) return;

    const devPrefix = this._appEnv.isDevelopment ? '[DEV]' : '[QA]';
    this._title.setTitle(`${devPrefix} ${this._title.getTitle()}`);
  }
}
