import {
  Component,
  ComponentRef,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import {
  PrintableComponent,
  PrintService,
} from '@app-services/ui/print.service';
import { Subscription } from 'rxjs';
import { TaskQueue } from '@app-utils/task-queue';

@Component({
  selector: 'cp-cus-print-container',
  template: '<ng-container #printContainer></ng-container>',
  styleUrls: ['./print-container.component.scss'],
})
export class PrintContainerComponent implements OnInit, OnDestroy {
  private _printRequestSubscription: Subscription;

  @ViewChild('printContainer', { read: ViewContainerRef })
  printContainer: ViewContainerRef;

  constructor(private _printService: PrintService) {}

  ngOnInit(): void {
    this._printRequestSubscription = this._printService.print$.subscribe(
      this.printRequestReceived.bind(this),
    );
  }

  ngOnDestroy(): void {
    this._printRequestSubscription?.unsubscribe();
  }

  private printRequestReceived(
    component: ComponentRef<PrintableComponent>,
  ): void {
    this.clearContainer();

    if (!component) return;

    this.printContainer.insert(component.hostView);

    component.instance.rendered$.subscribe(this.print.bind(this));
  }

  private print(isRendered: boolean): void {
    if (!isRendered) return;

    TaskQueue.scheduleOnMacroQueue(() => {
      window.print();
      this.clearContainer();
    });
  }

  private clearContainer(): void {
    this.printContainer?.clear();
  }
}
