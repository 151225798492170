import {
  Component,
  Input,
  Output,
  EventEmitter,
  ContentChild,
  OnInit,
} from '@angular/core';
import { ThemePalette } from '@angular/material/core';
import { MatLegacyMenu as MatMenu } from '@angular/material/legacy-menu';
import { ThemeService } from '@app-services/environment/theme.service';

/**
 * Component for showing split button for main action and side menu.
 */
@Component({
  selector: 'cp-cus-menu-button',
  templateUrl: './menu-button.component.html',
  styles: [':host{white-space: nowrap; display: flex;}'],
})
export class MenuButtonComponent implements OnInit {
  constructor(protected _themeService: ThemeService) {}

  /**
   * Text of the primary button
   */
  @Input()
  buttonText = '';
  /**
   * Tooltip for the primary button
   */
  @Input()
  buttonTooltip: string;
  /**
   * Option to not show tooltip
   */
  @Input()
  disableTooltip = false;
  /**
   * Color for the primary button
   */
  @Input()
  buttonColor: ThemePalette;
  /**
   * Color for the menu button. Defaults to the same color as the primary button.
   */
  @Input()
  menuButtonColor: ThemePalette;
  /**
   * Event for main button click.
   */
  @Output()
  readonly buttonClick = new EventEmitter<void>();
  /**
   * Event for menu click.
   */
  @Output()
  readonly menuClick = new EventEmitter<void>();
  /**
   * Link that the main button points to
   */
  @Input()
  link: string;
  /**
   * MatMenu of projected content
   */
  @ContentChild(MatMenu)
  matMenu: MatMenu;
  /**
   * Type of the button (solid or stroked). Default is solid.
   */
  @Input()
  buttonType: 'solid' | 'stroked' = 'solid';

  ngOnInit(): void {
    if (!this.menuButtonColor) {
      this.menuButtonColor = this.buttonColor;
    }
  }
}
