import { BaseClient } from '@app-clients/base.client';
import { HttpClient } from '@angular/common/http';
import { AppEnvironment } from '@app-services/environment/app-environment.service';
import { Injectable } from '@angular/core';
import { Account } from '@app-models/account/account';
import { ApiResponse } from '@app-responses/api-response';

/**
 * Implementation of the default client to communicate with
 * the accounts API.
 */
@Injectable()
export class AccountClient extends BaseClient {
  constructor(httpClient: HttpClient, appEnv: AppEnvironment) {
    super('account', httpClient, appEnv);
  }

  async getAccounts(): Promise<Account[]> {
    const response = await this.get<ApiResponse<Account[]>>();
    return response.data;
  }
}
