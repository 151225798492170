import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialCommonModule } from '@app-modules/material-common.module';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { UserMenuComponent } from '@app-components/ui/user-menu/user-menu.component';
import { BackButtonComponent } from '@app-components/ui/back-button/back-button.component';
import { CloseButtonComponent } from '@app-components/ui/close-button/close-button.component';
import { RetryButtonComponent } from '@app-components/ui/retry-button/retry-button.component';
import { MenuButtonComponent } from '@app-components/ui/menu-button/menu-button.component';
import { MatIconModule } from '@angular/material/icon';
import { SpinnerButtonComponent } from '@app-components/ui/spinner-button/spinner-button.component';
import { UiModule } from '@app-modules/ui.module';
import { RouterModule } from '@angular/router';
import { CancelButtonComponent } from '@app-components//ui/cancel-button/cancel-button.component';
import { PipeModule } from '@app-modules/utils/pipe.module';
import { FormsModule } from '@angular/forms';

const exportedDeclarations = [
  UserMenuComponent,
  BackButtonComponent,
  CloseButtonComponent,
  RetryButtonComponent,
  MenuButtonComponent,
  SpinnerButtonComponent,
  CancelButtonComponent,
];

@NgModule({
  declarations: [...exportedDeclarations],
  exports: [...exportedDeclarations],
  imports: [
    CommonModule,
    MaterialCommonModule,
    MatMenuModule,
    MatTooltipModule,
    MatIconModule,
    UiModule,
    RouterModule,
    PipeModule,
    FormsModule,
  ],
})
export class ButtonsModule {}
