import { Pipe, PipeTransform } from '@angular/core';

/**
 * Converts given text to different text cases.
 */
@Pipe({
  name: 'textCase',
})
export class TextCasePipe implements PipeTransform {
  private readonly DO_NOT_CAPITALIZE: string[] = [
    'a',
    'an',
    'the',
    'in',
    'with',
    'of',
    'by',
    'on',
    'and',
    'or',
    'but',
  ];
  transform(
    value: string,
    style: 'sentenceCase' | 'titleCase',
    excludedWords?: string[] | undefined,
  ): string {
    excludedWords ??= [];
    switch (style) {
      case 'sentenceCase':
        return this.convertToSentenceCase(value, excludedWords);
      case 'titleCase':
        return this.convertToTitleCase(value, excludedWords);
    }
  }

  private convertToTitleCase(value: string, excludedWords: string[]): string {
    if (!value) return value;

    const words = value.split(' ');

    for (let i = 0; i < words.length; i++) {
      if (excludedWords.includes(words[i])) continue;
      if (i > 0 && this.DO_NOT_CAPITALIZE.includes(words[i])) continue;

      words[i] = this.makeFirstLetterUpperCase(words[i]);
    }

    return words.join(' ');
  }

  private convertToSentenceCase(
    value: string,
    excludedWords: string[],
  ): string {
    if (!value) return value;

    const words = value.split(' ');

    words[0] = this.makeFirstLetterUpperCase(words[0]);
    if (words.length === 1) return words[0];

    for (let i = 1; i < words.length; i++) {
      if (excludedWords.includes(words[i])) continue;

      words[i] = this.makeFirstLetterLowerCase(words[i]);
    }

    return words.join(' ');
  }

  private makeFirstLetterUpperCase(value: string): string {
    return value.charAt(0).toUpperCase() + value.slice(1);
  }
  private makeFirstLetterLowerCase(value: string): string {
    return value.charAt(0).toLowerCase() + value.slice(1);
  }
}
