import { Component, Input } from '@angular/core';

/**
 * Component for displaying show pieces of data with label
 */
@Component({
  selector: 'cp-cus-label-view',
  template:
    '<span class="mr-2">{{label}}:</span><span><ng-content></ng-content></span>',
  styles: [':host{display: block;}'],
})
export class LabelViewComponent {
  /**
   * Label to show
   */
  @Input()
  label: string;
}
