import { normalize, NormalizedSchema, schema } from 'normalizr';
import { nameof } from 'ts-simple-nameof';
import { Assessment } from '@app-models/assessment/assessment';
import { assessmentVariantSchema } from '@app-state/assessment-variant';

/**
 * Normalizes assessments.
 * https://netbasal.com/working-with-normalized-data-in-akita-e626d4c67ca4
 */
export const assessmentSchema = new schema.Entity(
  'assessments',
  {
    assessmentVariants: [assessmentVariantSchema],
  },
  { idAttribute: nameof<Assessment>(a => a.assessmentId) },
);

export function normalizeAssessments(
  assessments: Assessment | Assessment[], // eslint-disable-next-line @typescript-eslint/no-explicit-any
): NormalizedSchema<{ [p: string]: { [p: string]: any } }, any> {
  if (!Array.isArray(assessments)) {
    assessments = [assessments];
  }

  return normalize(assessments, [assessmentSchema]);
}
