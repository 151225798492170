import { QueryConfig, QueryEntity } from '@datorama/akita';
import { AssessmentState } from '@app-state/assessment/assessment.state';
import { AssessmentStore } from '@app-state/assessment/assessment.store';
import { Injectable } from '@angular/core';

/**
 * Class for querying assessment store
 */
@Injectable()
@QueryConfig({
  sortBy: 'name',
})
export class AssessmentQuery extends QueryEntity<AssessmentState> {
  constructor(assessmentStore: AssessmentStore) {
    super(assessmentStore);
  }
}
