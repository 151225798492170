import { LoaderSize } from '@app-components/ui/loader/loader-size';

/**
 * Default options for loader component
 */
export function defaultLoaderOptions(): LoaderOptions {
  return {
    loaderSize: LoaderSize.Normal,
    solidBackground: true,
  };
}

/**
 * Options for configuring the loader component
 */
export interface LoaderOptions {
  loaderSize: LoaderSize;
  solidBackground: boolean;
}
