import { Component } from '@angular/core';
import { AppEnvironment } from '@app-services/environment/app-environment.service';

@Component({
  selector: 'cp-cus-api-unavailable',
  templateUrl: './api-unavailable.component.html',
})
export class ApiUnavailableComponent {
  constructor(protected readonly appEnvironment: AppEnvironment) {}

  protected refresh(): void {
    location.reload();
  }
}
