import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { KeycloakService, KeycloakAuthGuard } from 'keycloak-angular';

/**
 * Guard that protects routes from being accessed when user is not authenticated.
 */
@Injectable({
  providedIn: 'root',
})
export class AuthGuard extends KeycloakAuthGuard {
  constructor(
    protected router: Router,
    protected keycloakAngular: KeycloakService,
  ) {
    super(router, keycloakAngular);
  }

  async isAccessAllowed(): Promise<boolean> {
    if (!this.authenticated) {
      await this.keycloakAngular.login();
      return;
    }
    return true;
  }
}
