import { schema } from 'normalizr';
import { assessmentRevisionSchema } from '@app-state/assessment-revision/assessment-revision.normalizer';
import { nameof } from 'ts-simple-nameof';
import { AssessmentVariant } from '@app-models/assessment/assessment-variant';

/**
 * Normalizes variants.
 * https://netbasal.com/working-with-normalized-data-in-akita-e626d4c67ca4
 */
export const assessmentVariantSchema = new schema.Entity(
  'assessmentVariants',
  {
    assessmentRevisions: [assessmentRevisionSchema],
  },
  { idAttribute: nameof<AssessmentVariant>(v => v.assessmentVariantId) },
);
