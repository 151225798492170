import { Component, Input } from '@angular/core';

@Component({
  selector: 'cp-cus-custom-element',
  templateUrl: './custom-element.component.html',
})
export class CustomElementComponent {
  @Input({ required: true }) title = '';
  @Input({ required: true }) width = 0;
  @Input({ required: true }) height = 0;
  @Input({ required: true }) transform = '';
}
