import { ActiveState, EntityState } from '@datorama/akita';
import { User } from '@app-models/user/user';
import { UserFilter } from '@app-models/user/user-filter';

/**
 * User state model
 */
export interface UserState extends EntityState<User, string>, ActiveState {
  /**
   * UI state
   */
  ui: {
    filter: UserFilter;
  };
  /**
   * Indicates that there are no more entities to be loaded from the server
   */
  fullyLoaded: boolean;
}

/**
 * Gets initial user state
 */
export function getUserInitialState(): Partial<UserState> {
  return {
    ui: {
      filter: { active: true },
    },
    fullyLoaded: false,
  };
}
