import { Order, QueryConfig, QueryEntity } from '@datorama/akita';
import { AssessmentRevisionState } from '@app-state/assessment-revision/assessment-revision.state';
import { AssessmentRevisionStore } from '@app-state/assessment-revision/assessment-revision.store';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AssessmentRevision } from '@app-models/assessment';
import { nameof } from 'ts-simple-nameof';

@Injectable()
@QueryConfig({
  sortBy: nameof<AssessmentRevision>(r => r.revisionNumber),
  sortByOrder: Order.DESC,
})
export class AssessmentRevisionQuery extends QueryEntity<AssessmentRevisionState> {
  constructor(assessmentRevisionStore: AssessmentRevisionStore) {
    super(assessmentRevisionStore);
  }

  /**
   * Selects all revisions belonging to a variant
   * @param variantId ID of a variant
   */
  selectForVariant(variantId: string): Observable<AssessmentRevision[]> {
    return this.selectAll({
      filterBy: entity => entity.assessmentVariantId === variantId,
    });
  }
}
