<a
  routerLink="/app/account"
  mat-menu-item
  *ngIf="!authService.usesExternalIdentityProvider">
  <mat-icon>account_box</mat-icon>
  My Account
</a>

<button
  mat-menu-item
  [matMenuTriggerFor]="accountMenu"
  *ngIf="authService.userAccounts.length > 1"
  (menuOpened)="searchBar.focus()">
  <mat-icon>switch_account</mat-icon>
  <span>Switch Account</span>
</button>
<mat-divider
  *ngIf="
    !authService.usesExternalIdentityProvider ||
    authService.userAccounts.length > 1
  "></mat-divider>

<a
  mat-menu-item
  href="https://www.highmatch.com/support"
  target="_blank"
  rel="noopener">
  <mat-icon>contact_support</mat-icon>
  Support / Help
</a>
<mat-divider></mat-divider>

<button
  mat-menu-item
  *ngIf="!theme.darkMode === false; else darkMode"
  (click)="theme.darkMode = !theme.darkMode; $event.stopPropagation()">
  <mat-icon>dark_mode</mat-icon>
  Light Theme
</button>

<mat-divider></mat-divider>
<button mat-menu-item (click)="authService.logout()">
  <mat-icon>logout</mat-icon>
  <span>Log Out</span>
</button>

<mat-menu
  #accountMenu="matMenu"
  (closed)="searchBar.clear()"
  class="user-accounts-menu">
  <cp-cus-search-bar
    class="mb-2 mx-2"
    (keydown)="$event.stopPropagation()"
    (keydown.enter)="setLastAccount()"
    #searchBar
    [(ngModel)]="accountName"></cp-cus-search-bar>
  <div
    class="w-100 flex flex-col items-center mb-1 no-accounts-text"
    *ngIf="visibleAccounts.length === 0">
    <span class="block my-3 dark:text-white">No Accounts Found</span>
    <button
      class="w-fit"
      mat-raised-button
      color="primary"
      (click)="searchBar.clear(); $event.stopPropagation()">
      Clear Filter
    </button>
  </div>
  <div *ngIf="visibleAccounts.length > 0" class="user-accounts">
    <button
      mat-menu-item
      *ngFor="let account of visibleAccounts"
      (click)="authService.switchAccount(account)">
      {{ account.name }}
    </button>
  </div>
</mat-menu>

<ng-template #darkMode>
  <button
    mat-menu-item
    (click)="theme.darkMode = !theme.darkMode; $event.stopPropagation()">
    <mat-icon>light_mode</mat-icon>
    Dark Theme
  </button>
</ng-template>
