import { schema } from 'normalizr';
import { nameof } from 'ts-simple-nameof';
import { AssessmentRevision } from '@app-models/assessment/assessment-revision';

/**
 * Normalizes assessment revisions.
 * https://netbasal.com/working-with-normalized-data-in-akita-e626d4c67ca4
 */
export const assessmentRevisionSchema = new schema.Entity(
  'assessmentRevisions',
  {},
  { idAttribute: nameof<AssessmentRevision>(r => r.assessmentRevisionId) },
);
