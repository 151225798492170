import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  forwardRef,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatLegacyInput as MatInput } from '@angular/material/legacy-input';

/**
 * Component for displaying search bar for single string value.
 */
@Component({
  selector: 'cp-cus-search-bar',
  templateUrl: './search-bar.component.html',
  styleUrls: ['./search-bar.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SearchBarComponent),
      multi: true,
    },
  ],
})
export class SearchBarComponent implements ControlValueAccessor {
  // This value should be set when initial 'writeValue' is called
  // before the input is initialized.
  protected initialValue?: string = '';

  /**
   * Placeholder for the search field
   */
  @Input()
  placeholder = 'Search';
  /**
   * Enable clear button
   */
  @Input()
  clearable = true;

  @ViewChild('searchInput', { read: MatInput })
  searchInput?: MatInput;

  @Output()
  valueChanged = new EventEmitter<string>();

  constructor(private readonly _cdr: ChangeDetectorRef) {}

  public focus(): void {
    if (!this.searchInput) return;

    this.searchInput.focus();
    this._cdr.detectChanges();
  }

  onChange: (...args: unknown[]) => void = (v: string) =>
    this.valueChanged.emit(v);

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onTouch: (...args: unknown[]) => void = () => {};

  writeValue(value: string): void {
    if (!this.searchInput) {
      this.initialValue = value;
      return;
    }

    this.searchInput.value = value;
  }
  registerOnChange(fn: (newValue: unknown) => void): void {
    this.onChange = (value: string) => {
      fn(value);
      this.valueChanged.emit(value);
    };
  }
  registerOnTouched(fn: () => void): void {
    this.onTouch = fn;
  }

  /**
   * Clears the current value
   */
  clear(): void {
    if (!this.clearable) return;

    this.writeValue(null);
    this.onChange(null);
    this.searchInput.focus();
  }
}
