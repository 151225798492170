import { NgModule } from '@angular/core';
import { SuccessModalComponent } from '@app-components/modals/success/success-modal.component';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatIconModule } from '@angular/material/icon';
import { ErrorModalComponent } from '@app-components/modals/error/error-modal.component';
import { DialogModalComponent } from '@app-components/modals/dialog/dialog-modal.component';
import { CommonModule } from '@angular/common';
import { ButtonsModule } from '@app-modules/ui/buttons.module';
import { ApiUnavailableComponent } from '@app-components/modals/api-unavailable/api-unavailable.component';
import { MatLegacyTooltipModule } from '@angular/material/legacy-tooltip';

@NgModule({
  declarations: [
    SuccessModalComponent,
    ErrorModalComponent,
    DialogModalComponent,
    ApiUnavailableComponent,
  ],
  imports: [
    MatDialogModule,
    MatButtonModule,
    MatIconModule,
    CommonModule,
    ButtonsModule,
    MatLegacyTooltipModule,
  ],
  exports: [
    SuccessModalComponent,
    ErrorModalComponent,
    ApiUnavailableComponent,
    DialogModalComponent,
  ],
})
export class ModalModule {}
