import { EntityStore, StoreConfig } from '@datorama/akita';
import { AssessmentState } from '@app-state/assessment/assessment.state';
import { Stores } from '@app-state/shared/stores-configuration';

/**
 * Store holding assessment entities
 */
@StoreConfig(Stores.assessments)
export class AssessmentStore extends EntityStore<AssessmentState> {
  constructor() {
    super();
  }
}
