import { Pipe, PipeTransform } from '@angular/core';

/**
 * Pipe converting boolean value to 'yes' or 'no'.
 * Can accept a parameter to capitalize the words. Default is true.
 */
@Pipe({
  name: 'yesNo',
})
export class YesNoPipe implements PipeTransform {
  transform(value: boolean, capitalize = true): string {
    if (capitalize) {
      return value ? 'Yes' : 'No';
    } else {
      return value ? 'yes' : 'no';
    }
  }
}
