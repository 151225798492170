/**
 * Converts date to DateTime
 * @param date Date to convert
 * @param startOfDay Whether the date is start or end of the day
 * @private
 */
export function convertDateToDateTime(date: Date, startOfDay = true): string {
  const year = date.getFullYear();
  const month = ('0' + (date.getMonth() + 1)).slice(-2);
  const day = ('0' + date.getDate()).slice(-2);

  return `${year}-${month}-${day}T${
    startOfDay ? '00:00:00.0' : '23:59:59.999'
  }`;
}
