import { EntityStore, StoreConfig } from '@datorama/akita';
import { getUserInitialState, UserState } from '@app-state/user/user.state';
import { Injectable } from '@angular/core';
import { Stores } from '@app-state/shared/stores-configuration';

/**
 * Store for storing user entities
 */
@Injectable()
@StoreConfig(Stores.users)
export class UserStore extends EntityStore<UserState> {
  constructor() {
    super(getUserInitialState());
  }

  /**
   * Updates the ui state
   * @param ui UI state
   */
  updateUi(ui: UserState['ui']): void {
    this.update(state => ({
      ui: {
        ...state.ui,
        ...ui,
      },
    }));
  }
}
