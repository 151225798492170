export enum ApplicationEnvironment {
  Development = 'development',
  Staging = 'staging',
  Production = 'production',
}

export interface Environment {
  version: string;
  applicationEnvironment: ApplicationEnvironment;
  baseApiUrl: string;
  participantBaseUrl: string;
  publisherUrl: string;
  reportUrl: string;
}
