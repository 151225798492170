import {
  ComponentFactoryResolver,
  ComponentRef,
  Injectable,
  Injector,
  Type,
} from '@angular/core';
import { BehaviorSubject } from 'rxjs';

/**
 * Interface for any component that wants to use print service.
 * @see PrintService
 */
export interface PrintableComponent {
  rendered$: BehaviorSubject<boolean>;
}

/**
 * Default implementation for PrintableComponent
 * @see PrintableComponent
 * @see PrintService
 */
export abstract class Printable implements PrintableComponent {
  readonly rendered$: BehaviorSubject<boolean>;

  protected constructor(defaultState = true) {
    this.rendered$ = new BehaviorSubject<boolean>(defaultState);
  }

  protected didFinishRendering(): void {
    this.rendered$.next(true);
  }
}

/**
 * Service that can print components that implement the PrintableComponent interface.
 * @see PrintableComponent
 */
@Injectable()
export class PrintService {
  /**
   * Observable that emits when a print is requested
   */
  readonly print$ =
    new BehaviorSubject<ComponentRef<PrintableComponent> | null>(null);

  constructor(
    private _componentFactoryResolver: ComponentFactoryResolver,
    private _injector: Injector,
  ) {}

  /**
   * Creates and prints an component
   * @param component Component to print. Must implement PrintableComponent
   * @param params Parameters that will map to the input values of the component instance
   * @param injector Optional. Injector to use for any services.
   */
  print<TComponent extends PrintableComponent>(
    component: Type<TComponent>,
    params?: Partial<{ [key in keyof TComponent]: unknown }>,
    injector?: Injector,
  ): void {
    const componentRef = this._componentFactoryResolver
      .resolveComponentFactory(component)
      .create(injector ?? this._injector);

    this.mapInstanceProperties(componentRef.instance, params);

    this.print$.next(componentRef);
  }

  private mapInstanceProperties(
    instance: PrintableComponent,
    params: { [key: string]: unknown },
  ): void {
    for (const propertyName of Object.getOwnPropertyNames(params)) {
      instance[propertyName] = params[propertyName];
    }
  }
}
