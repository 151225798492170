import { Store, StoreConfig } from '@datorama/akita';
import { createInitialSessionState, SessionState } from './session.state';
import { Stores } from '@app-state/shared/stores-configuration';
import { BehaviorSubject } from 'rxjs';

/**
 * Store that holds any data related to currently logged in session
 */
@StoreConfig(Stores.session)
export class SessionStore extends Store<SessionState> {
  readonly sessionState$: BehaviorSubject<SessionState>;

  constructor() {
    const initState = createInitialSessionState();
    super(initState);

    this.sessionState$ = new BehaviorSubject<SessionState>(initState);
  }

  akitaPreUpdate(
    previous: Readonly<SessionState>,
    nextState: Readonly<SessionState>,
  ): SessionState {
    this.sessionState$.next(nextState);
    return super.akitaPreUpdate(previous, nextState);
  }
}
