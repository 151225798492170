import { Component, OnInit } from '@angular/core';
import { FreezeUi } from '@app-services/ui/freeze-ui.service';
import { KeycloakService } from 'keycloak-angular';

@Component({
  selector: 'cp-cus-user-not-found',
  templateUrl: './user-not-found.component.html',
})
export class UserNotFoundComponent implements OnInit {
  constructor(
    private readonly _freeze: FreezeUi,
    private readonly _keycloak: KeycloakService,
  ) {}

  async ngOnInit(): Promise<void> {
    if (await this._keycloak.isLoggedIn()) {
      await this._keycloak.logout(location.href);
    }
    this._freeze.unfreezeUi();
  }
}
