import { Injectable } from '@angular/core';
import { AccountState } from './account.state';
import { QueryEntity } from '@datorama/akita';
import { AccountStore } from './account.store';

/**
 * Class for querying the data from account store
 */
@Injectable()
export class AccountQuery extends QueryEntity<AccountState> {
  constructor(private _accountStore: AccountStore) {
    super(_accountStore);
  }
}
