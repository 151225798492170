<ng-container
  *ngIf="
    buttonType === 'stroked' && !_themeService.darkMode;
    else solidButtons
  ">
  <button
    *ngIf="!link"
    mat-stroked-button
    [color]="buttonColor"
    [matTooltipDisabled]="disableTooltip"
    [matTooltip]="buttonTooltip ?? buttonText | textCase: 'titleCase'"
    class="rounded-r-none flex-1"
    (click)="buttonClick.emit()">
    {{ buttonText }}
  </button>
  <a
    *ngIf="link"
    mat-stroked-button
    [color]="buttonColor"
    [routerLink]="link"
    [matTooltipDisabled]="disableTooltip"
    [matTooltip]="buttonTooltip ?? buttonText | textCase: 'titleCase'"
    class="rounded-r-none cursor-pointer">
    {{ buttonText }}
  </a>
  <button
    mat-stroked-button
    class="min-w-0 rounded-l-none border-l-0 px-1"
    matTooltip="Show More"
    [matMenuTriggerFor]="matMenu"
    [color]="menuButtonColor">
    <mat-icon> more_horiz </mat-icon>
  </button>
</ng-container>

<ng-template #solidButtons>
  <button
    *ngIf="!link"
    mat-flat-button
    [color]="buttonColor"
    [matTooltipDisabled]="disableTooltip"
    [matTooltip]="buttonTooltip ?? buttonText | textCase: 'titleCase'"
    class="rounded-r-none flex-1"
    (click)="buttonClick.emit()">
    {{ buttonText }}
  </button>
  <a
    *ngIf="link"
    mat-flat-button
    [color]="buttonColor"
    [routerLink]="link"
    [matTooltipDisabled]="disableTooltip"
    [matTooltip]="buttonTooltip ?? buttonText | textCase: 'titleCase'"
    class="rounded-r-none cursor-pointer">
    {{ buttonText }}
  </a>
  <button
    mat-flat-button
    class="min-w-0 rounded-l-none ml-px px-1"
    matTooltip="Show More"
    [matMenuTriggerFor]="matMenu"
    [color]="menuButtonColor">
    <mat-icon> more_horiz </mat-icon>
  </button>
</ng-template>

<ng-content select="mat-menu"></ng-content>
