import * as environmentBase from './environment.base';
import {
  ApplicationEnvironment,
  Environment,
} from '@environments/application-environment';
import { KeycloakConfig } from 'keycloak-js';

export const keycloakConfig: KeycloakConfig = {
  ...environmentBase.keycloakConfig,
  url: 'https://login.highmatch.com/auth',
};

export const environment: Environment = {
  ...environmentBase.environment,
  applicationEnvironment: ApplicationEnvironment.Production,
  baseApiUrl: 'https://api.compass.highmatch.com/p/',
  participantBaseUrl: 'https://asmt.app/',
  publisherUrl: 'https://publisher.highmatch.com/',
  reportUrl: 'https://report.highmatch.com',
};
