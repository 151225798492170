import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { LoaderSize } from '@app-components/ui/loader/loader-size';
import {
  defaultLoaderOptions,
  LoaderOptions,
} from '@app-components/ui/loader/loader-options';

/**
 * Component for showing loader with optional text hiding everything else in the parent component.
 */
@Component({
  selector: 'cp-cus-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoaderComponent {
  private _options: LoaderOptions = defaultLoaderOptions();

  /**
   * Text that should be displayed while loading
   */
  @Input()
  loadingText: string;
  /**
   * Whether to show the loader.
   */
  @Input()
  isLoading = false;

  /**
   * Setter for the options of the loader
   * @param value Options object
   */
  @Input()
  set options(value: LoaderOptions) {
    this._options = value ?? defaultLoaderOptions();
  }

  /**
   * Getter for the options of the loader
   */
  get options(): LoaderOptions {
    return this._options;
  }

  readonly LoaderSize = LoaderSize;
}
