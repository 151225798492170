import { AccountState } from './account.state';
import { EntityStore, StoreConfig } from '@datorama/akita';
import { Injectable } from '@angular/core';
import { Stores } from '@app-state/shared/stores-configuration';

/**
 * State store for user accounts
 */
@Injectable()
@StoreConfig(Stores.accounts)
export class AccountStore extends EntityStore<AccountState> {
  constructor() {
    super();
  }
}
