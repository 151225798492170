import { NgModule } from '@angular/core';
import { IndexLetterPipe } from '@app-pipes/index-letter-pipe';
import { PhonePipe } from '@app-pipes/phone.pipe';
import { TimePipe } from '@app-pipes/time.pipe';
import { SortPipe } from '@app-pipes/sort.pipe';
import { YesNoPipe } from '@app-pipes/yes-no.pipe';
import { TextCasePipe } from '@app-pipes/text-case.pipe';

const pipes = [
  IndexLetterPipe,
  PhonePipe,
  TimePipe,
  SortPipe,
  YesNoPipe,
  TextCasePipe,
];

@NgModule({
  declarations: [...pipes],
  exports: [...pipes],
})
export class PipeModule {}
