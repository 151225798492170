import { SessionStore } from '@app-state/session/session.store';
import { Injectable } from '@angular/core';

/**
 * Class for querying session store
 */
@Injectable()
export class SessionQuery {
  /**
   * Gets an observable for current session state
   */
  readonly selectState$ = this._store.sessionState$;

  constructor(private readonly _store: SessionStore) {}
}
