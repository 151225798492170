import { NgModule } from '@angular/core';
import {
  MAT_LEGACY_SNACK_BAR_DEFAULT_OPTIONS as MAT_SNACK_BAR_DEFAULT_OPTIONS,
  MatLegacySnackBarModule as MatSnackBarModule,
} from '@angular/material/legacy-snack-bar';
import { NotificationCenter } from '@app-services/ui/notification-center.service';
import { ModalModule } from '@app-modules/ui/modal.module';

@NgModule({
  imports: [MatSnackBarModule, ModalModule],
  providers: [
    NotificationCenter,
    {
      provide: MAT_SNACK_BAR_DEFAULT_OPTIONS,
      useValue: {
        duration: 4000,
        verticalPosition: 'top',
      },
    },
  ],
})
export class NotificationModule {}
