import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { AssessmentContent } from '@app-models/assessment';

@Component({
  selector: 'cp-cus-content-renderer',
  templateUrl: './assessment-content-renderer.component.html',
  styleUrls: ['./assessment-content-renderer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AssessmentContentRendererComponent {
  /**
   * Content to render
   */
  @Input()
  assessmentContent: AssessmentContent;
  /**
   * Indicates whether the content should use Bootstrap styling.
   * Defaults to true.
   */
  @Input()
  useBootstrap = true;
  /**
   * If true the component renders the name
   * of the content.
   */
  @Input()
  showContentName = true;
}
