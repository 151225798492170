import { Component, Input } from '@angular/core';

/**
 * Component for rendering button that indicates backward navigation
 */
@Component({
  selector: 'cp-cus-back-button',
  templateUrl: './back-button.component.html',
})
export class BackButtonComponent {
  /**
   * Tooltip to show for the button
   */
  @Input()
  tooltip: string;
}
