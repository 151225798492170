import { StoreConfigOptions } from '@datorama/akita';
import { Account } from '@app-models/account/account';
import { nameof } from 'ts-simple-nameof';
import { ParticipantAssessmentRevision } from '@app-models/assessment/participant-assessment-revision';
import { Participant } from '@app-models/participant/participant';
import { ScoringBucket } from '@app-models/assessment/scoring-bucket';
import { Assessment } from '@app-models/assessment/assessment';
import { AssessmentRevision } from '@app-models/assessment/assessment-revision';
import { Question } from '@app-models/content/question';
import { ParticipantRevisionResponse } from '@app-models/participant';
import { User } from '@app-models/user/user';
import { AssessmentVariant } from '@app-models/assessment/assessment-variant';

/**
 * Configuration for various stores
 */
export class Stores {
  static readonly statistics: StoreConfigOptions = {
    name: 'statistics',
  };
  static readonly session: StoreConfigOptions = {
    name: 'session',
  };
  static readonly accounts: StoreConfigOptions = {
    name: 'accounts',
    idKey: nameof<Account>(a => a.accountId),
  };
  static readonly participantAssessmentRevisions: StoreConfigOptions = {
    name: 'participantAssessmentRevision',
    idKey: nameof<ParticipantAssessmentRevision>(
      r => r.participantAssessmentRevisionId,
    ),
  };
  static readonly participants: StoreConfigOptions = {
    name: 'participants',
    idKey: nameof<Participant>(p => p.participantId),
    resettable: true,
  };
  static readonly scoringBuckets: StoreConfigOptions = {
    name: 'scoringBuckets',
    idKey: nameof<ScoringBucket>(p => p.scoringBucketId),
    resettable: true,
  };
  static readonly assessments: StoreConfigOptions = {
    name: 'assessments',
    idKey: nameof<Assessment>(p => p.assessmentId),
    resettable: true,
  };
  static readonly assessmentRevisions: StoreConfigOptions = {
    name: 'assessmentRevisions',
    idKey: nameof<AssessmentRevision>(p => p.assessmentRevisionId),
    resettable: true,
  };
  static readonly assessmentQuestions: StoreConfigOptions = {
    name: 'questions',
    idKey: nameof<Question>(q => q.questionId),
  };
  static readonly participantResponseState: StoreConfigOptions = {
    name: 'participantResponse',
    idKey: nameof<ParticipantRevisionResponse>(
      r => r.participantAssessmentRevisionId,
    ),
  };
  static readonly users: StoreConfigOptions = {
    name: 'users',
    idKey: nameof<User>(u => u.personId),
    resettable: true,
  };
  static readonly assessmentContent: StoreConfigOptions = {
    name: 'assessmentContent',
    // AssessmentContent does not have a direct ID on it so we are using
    // internally generated ID to keep track of entities
    idKey: '_id',
  };
  static readonly assessmentVariants: StoreConfigOptions = {
    name: 'assessmentVariants',
    idKey: nameof<AssessmentVariant>(v => v.assessmentVariantId),
  };
}
