<div
  class="flex min-h-screen flex-col justify-center items-center overflow-hidden">
  <div
    class="bg-white shadow-md ring-1 ring-gray-900/5 md:w-1/3 sm:rounded-lg p-4">
    <div class="flex items-center gap-2 text-2xl">
      <mat-icon class="text-warnLight-500"> error </mat-icon>
      <span>Login Failed</span>
    </div>

    <div class="mt-2">
      You do not have an active account matching your email address in the
      HighMatch system. Please contact the person or group who provisions
      HighMatch user accounts at your company and request a new (or updated)
      HighMatch user account. Once your HighMatch account is active, you can try
      to complete the login process.
    </div>
  </div>
</div>
