import { NgModule } from '@angular/core';
import { SectionTitleComponent } from '@app-components/ui/section-title/section-title.component';
import { RequiredRoleViewComponent } from '@app-components/authorization/required-role-view/required-role-view.component';
import { CommonModule } from '@angular/common';

@NgModule({
  imports: [CommonModule],
  exports: [SectionTitleComponent, RequiredRoleViewComponent],
  declarations: [SectionTitleComponent, RequiredRoleViewComponent],
})
export class CommonUiModule {}
