import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import {
  ApplicationEnvironment,
  Environment,
} from '@environments/application-environment';

/**
 * Service that provides environment configuration via injectable service
 * @see Environment
 */
@Injectable({
  providedIn: 'root',
})
export class AppEnvironment implements Environment {
  /**
   * Version of the application
   */
  readonly version: string;
  /**
   * Environment for the application
   */
  readonly applicationEnvironment: ApplicationEnvironment;
  /**
   * Base URL for the API
   */
  readonly baseApiUrl: string;
  /**
   * Base URL for the participant site
   */
  readonly participantBaseUrl: string;
  /**
   * URL for Compass editor
   */
  readonly publisherUrl: string;

  /**
   * URL for reports
   */
  readonly reportUrl: string;

  constructor() {
    this.mapProperties();
  }

  /**
   * Indicates whether current environment is production environment.
   */
  get isProduction(): boolean {
    return this.applicationEnvironment === ApplicationEnvironment.Production;
  }
  /**
   * Indicates whether current environment is staging environment.
   */
  get isStaging(): boolean {
    return this.applicationEnvironment === ApplicationEnvironment.Staging;
  }
  /**
   * Indicates whether current environment is development environment.
   */
  get isDevelopment(): boolean {
    return this.applicationEnvironment === ApplicationEnvironment.Development;
  }

  private mapProperties(): void {
    for (const propertyName of Object.getOwnPropertyNames(environment)) {
      this[propertyName] = environment[propertyName];
    }
  }
}
