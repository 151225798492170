/**
 * Subscription manager handles active subscriptions
 * for an instance and simplifies subscription destruction.
 */
import { Subject, Subscription } from 'rxjs';

export class SubscriptionManager {
  private _subscriptions: Subscription[] = [];

  constructor(...subscriptions: Subscription[]) {
    this._subscriptions = subscriptions;
  }

  /**
   * Adds a subscription to be managed
   * @param subscription Subscription to add
   * @param withReset If set to true it will reset the subscription store and then add the new subscription
   */
  add(subscription: Subscription, withReset = false): void {
    if (!subscription) return;
    if (withReset) this.reset();

    this._subscriptions.push(subscription);
  }

  /**
   * Subscribes to a subject and stores the subscription
   * @param subject Subject to subscribe
   * @param handler Handler for the subscription
   * @param bind Object to bind to handler
   * @param withReset Reset the store before subscription
   */
  subscribe<TReturn>(
    subject: Subject<TReturn>,
    handler: (value: TReturn) => void,
    bind?: unknown,
    withReset = false,
  ): void {
    if (withReset) this.reset();

    const subscription = bind
      ? subject.subscribe(handler.bind(bind))
      : subject.subscribe(handler);
    this.add(subscription);
  }

  /**
   * Unsubscribes from all active subscriptions
   */
  unsubscribeAll(): void {
    for (const subscription of this._subscriptions) {
      subscription?.unsubscribe();
    }
  }

  /**
   * Unsubscribes from all currently held subscriptions and clears
   * then from memory.
   */
  reset(): void {
    if (this._subscriptions.length === 0) return;

    this.unsubscribeAll();
    this._subscriptions = [];
  }
}
