import { Injectable } from '@angular/core';

/**
 * Service responsible for logging errors, events, etc.
 */
@Injectable({
  providedIn: 'root',
})
export class LoggingService {
  /**
   * Logs an error to all error providers
   * @param error Error to log
   */
  logError(error: unknown): void {
    // eslint-disable-next-line no-console
    console.error(error);
  }
}
