import { Component, Input } from '@angular/core';

/**
 * Component for rendering button that indicates that something should close
 */
@Component({
  selector: 'cp-cus-close-button',
  templateUrl: './close-button.component.html',
})
export class CloseButtonComponent {
  /**
   * Tooltip to show with the button.
   */
  @Input()
  tooltip = 'Close';
}
