import { Component, Input } from '@angular/core';

/**
 * Sizes for the spinner
 */
export enum SpinnerSize {
  XSmall,
  Small,
  Medium,
  Large,
}

export enum TextPosition {
  Top,
  Right,
  Bottom,
  Left,
}

/**
 * Component for displaying spinner.
 */
@Component({
  selector: 'cp-cus-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss'],
})
export class SpinnerComponent {
  TextPosition = TextPosition;
  SpinnerSize = SpinnerSize;

  /**
   * Defines the spinner size
   */
  @Input()
  size: SpinnerSize = SpinnerSize.Medium;
  /**
   * Text to show with the spinner
   */
  @Input()
  text: string;
  @Input()
  textPosition: TextPosition = TextPosition.Bottom;

  isTextVertical(): boolean {
    return (
      this.textPosition === TextPosition.Bottom ||
      this.textPosition === TextPosition.Top
    );
  }
}
